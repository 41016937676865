import React from "react";
import { Link } from "gatsby";
import { Layout } from "StaticPages/Layouts";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { PageSummary } from "StaticPages/Components/Commons/PageSummary";
import { Box, Grid, Hidden, Typography, useTheme } from "@mui/material";
import { SectionTitle2 } from "StaticPages/Components/Commons/SectionTitle2";
import { SectionText1 } from "StaticPages/Components/Commons/SectionText1";
import { Spacer } from "mui-extensions/src/Components/Spacer";
import { css } from "@mui/material";
import { ServiceImageCollectionItem } from "StaticPages/Components/Commons/ServiceImageCollectionItem";
import { SectionTitle } from "StaticPages/Components/Top/SectionTitle";
import { ScrollTrigger } from "mui-extensions/src/ScrollTrigger";
import { FadeAndSlideAnimation } from "StaticPages/Components/Animations/FadeAndSlideAnimation";
import { FadeAndSlideScrollTriggerAnimation } from "StaticPages/Components/FadeAndSlideScriollTriggerAnimation";

export default () => {
    const { t } = useTranslation();
    return <>
        <Helmet title={t("AI | VISUALIZ inc")} />
        <Layout>
            <Main />
        </Layout>
    </>;
};

const Main = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    return <>
        <main>
            <PageSummary
                description={<>
                    {t("数百万ページから")}<br />
                    {t("必要な情報だけをラベリング。")}<br />
                    {t("当社のSaaSサービスによって")}<br />
                    {t("誰もが大規模コンテンツの")}<br />
                    {t("運営者になる事が可能です。")}
                </>}
                src="/images/AI-TOP_top.jpg"
                title={t("AI開発事業")}
                subTitle={t("AI")}
            />

            <Box
                p={{ xs: 3, sm: 4, md: 6 }}
            >
                <Typography
                    variant="h6"
                    component="h2"
                    css={css({ textAlign: "center" })}
                >
                    {t("正しいアノテーションデータ設計と")}<br />
                    {t("厄介なTensorFlowカスタマイズはお")}<Hidden smUp><br /></Hidden>
                    {t("任せください。")}
                </Typography>
            </Box>

            <Box
                maxWidth="720px"
                mx="auto"
                p={2}
            >
                <ScrollTrigger once>
                    {state =>
                        <Grid container sx={{ height: "100%" }}>
                            <Grid item xs={12} sm={4} sx={{ height: "100%" }}>
                                <Box p={1} my="auto" sx={{ height: "100%" }}>
                                    <FadeAndSlideAnimation
                                        in={state === "entered"}
                                        delay={0}
                                        transform={{
                                            translate: { y: "60px", x: "0" },
                                            rotate: "0deg",
                                            scale: 0.9
                                        }}>
                                        <Box css={blue}>
                                            <Spacer />
                                            <Typography>{t("特徴１")}</Typography>
                                            <Typography>{t("毎日数百万ページ")}</Typography>
                                            <Typography>{t("から自動収集可能")}</Typography>
                                            <Spacer />
                                        </Box>
                                    </FadeAndSlideAnimation>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4} sx={{ height: "100%" }}>
                                <Box p={1} my="auto" sx={{ height: "100%" }}>
                                    <FadeAndSlideAnimation
                                        in={state === "entered"}
                                        delay={200}
                                        transform={{
                                            translate: { y: "60px", x: "0" },
                                            rotate: "0deg",
                                            scale: 0.9
                                        }}>
                                        <Box css={blue}>
                                            <Spacer />
                                            <Typography>{t("特徴２")}</Typography>
                                            <Typography>{t("自動ラベリング")}</Typography>
                                            <Typography>{t("DBへの保存")}</Typography>
                                            <Spacer />
                                        </Box>
                                    </FadeAndSlideAnimation>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4} sx={{ height: "100%" }}>
                                <Box p={1} my="auto" sx={{ height: "100%" }}>
                                    <FadeAndSlideAnimation
                                        in={state === "entered"}
                                        delay={400}
                                        transform={{
                                            translate: { y: "60px", x: "0" },
                                            rotate: "0deg",
                                            scale: 0.9
                                        }}>
                                        <Box css={blue}>
                                            <Spacer />
                                            <Typography>{t("特徴３")}</Typography>
                                            <Typography>{t("検索機能付サイトへ")}</Typography>
                                            <Typography>{t("自動公開")}</Typography>
                                            <Spacer />
                                        </Box>
                                    </FadeAndSlideAnimation>
                                </Box>
                            </Grid>
                        </Grid>
                    }

                </ScrollTrigger>
            </Box>

            <Box
                maxWidth="720px"
                mx="auto"
                mt={{ xs: 3, sm: 4, md: 5 }}
                p={3}
            >
                <FadeAndSlideScrollTriggerAnimation>
                    <Box
                        p={3}
                        border={`2px solid ${theme.palette.text.primary}`}
                    >
                        <Typography variant="h5" component="h2">{t("VISUALIZ AI の主な特徴")}</Typography>
                        <Box mt={4}>
                            <Typography variant="body1" component="p">
                                {t("日本語と言う曖昧さを含んだ言語に加え、")}<br />
                                {t("ほぼルールが存在しないマークアップ言語の組み合わせに対応する必要があり、")}<br />
                                {t("且つスーパーコンピュータではなく市販のPCで動作可能な軽さを実現する")}<br />
                                {t("ための新しいAI構築方法を提供させていただきます。")}
                            </Typography>
                        </Box>
                    </Box>
                </FadeAndSlideScrollTriggerAnimation>
            </Box>

            <Box
                my={{ xs: 2, sm: 3, md: 4 }}
                maxWidth="900px"
                mx="auto"
                p={{ xs: 1, sm: 2 }}
            >
                <Grid container>
                    <Grid xs={12} sm={6} item>
                        <Box p={{ xs: 2 }}>
                            <ServiceImageCollectionItem
                                src="/images/AI-TOP_API-01.jpg"
                                title={t("クローラーサービス")}
                                description={<>
                                    {t("ターゲットサイトを定期循環し、数千サイト、数百万ページに及ぶクローリングにも対応。")}
                                </>}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                        <Box p={{ xs: 2 }}>
                            <ServiceImageCollectionItem
                                src="/images/AI-TOP_API-02.jpg"
                                title={t("AIカスタマイズ")}
                                description={<>
                                    {t("お客様の望む情報収集が可能なカスタマイズ、")}<br />
                                    {t("ラベリング及び自動分類。")}
                                </>}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                        <Box p={{ xs: 2 }}>
                            <ServiceImageCollectionItem
                                src="/images/AI-TOP_API-03.jpg"
                                title={t("アノテーションマネジメント")}
                                description={<>
                                    {t("機械学習用データ制作用機能一式の提供。")}<br />
                                </>}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                        <Box p={{ xs: 2 }}>
                            <ServiceImageCollectionItem
                                src="/images/AI-TOP_API-04.jpg"
                                title={t("情報サイト構築、情報サービス展開")}
                                description={<>
                                    {t("SaaS（Software as a Service）について")}<br />
                                </>}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box
                p={{ xs: 3, sm: 4, md: 6 }}
                maxWidth="1080px"
                mx="auto"
            >
                <SectionTitle2
                    title={t("BIDnavi")}
                />
                <SectionText1>
                    {t("国内7,000弱のサイト、1,200万ページから毎日入札及びプロポーザルに関する情報と補助金・助成金情報を入手しワンストップで公開するサービス")}<br />
                    {t("「 BIDnavi」のご紹介です。")}
                </SectionText1>


                <Box pt={1}>
                    <ScrollTrigger once>
                        {
                            state =>
                                <Grid container spacing={2}>
                                    <Grid xs={12} sm={6} item>
                                        <FadeAndSlideAnimation in={state === "entered"}>
                                            <img src="/images/AI-TOP_BIDnavi.jpg" alt="BIDnavi"
                                                css={css({
                                                    width: "100%",
                                                    height: "100%",
                                                    border: "1px solid black"
                                                })} />
                                        </FadeAndSlideAnimation>
                                    </Grid>
                                    <Grid xs={12} sm={6} item>
                                        <FadeAndSlideAnimation in={state === "entered"}>
                                            <img src="/images/AI-TOP_nyusatsu.jpg" alt="入札事例"
                                                css={css({
                                                    width: "100%",
                                                    height: "100%",
                                                    border: "1px solid black"
                                                })} />
                                        </FadeAndSlideAnimation>
                                    </Grid>
                                </Grid>
                        }
                    </ScrollTrigger>
                </Box>

                <Box mt={3}>
                    <FadeAndSlideScrollTriggerAnimation>
                        <Link
                            target="_blank"
                            to="https://bidnavi.jp"
                            css={[
                                a,
                                css({
                                    background: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText
                                })
                            ]}
                        >
                            {t("BIDnaviサイトへ")}
                        </Link>
                    </FadeAndSlideScrollTriggerAnimation>
                </Box>
            </Box>

            <Box maxWidth="1080px"
                p={{ xs: 2, sm: 3, md: 5 }}
                mt={{
                    xs: 2,
                    sm: 4,
                    md: 8
                }}
                mx="auto"
            >
                <ScrollTrigger>
                    {
                        state =>
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <FadeAndSlideAnimation in={state === "entered"}>
                                        <Box p={1}>
                                            <Link
                                                to="/ai-about"
                                                css={[
                                                    a,
                                                    css({
                                                        background: theme.palette.secondary.main,
                                                        color: theme.palette.secondary.contrastText
                                                    })
                                                ]}>
                                                {t("AIについて")}
                                            </Link>
                                        </Box>
                                    </FadeAndSlideAnimation>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FadeAndSlideAnimation in={state === "entered"}>
                                        <Box p={1}>
                                            <Link
                                                to="/contact"
                                                css={[
                                                    a,
                                                    css({
                                                        background: theme.palette.primary.main,
                                                        color: theme.palette.primary.contrastText
                                                    })
                                                ]}
                                            >
                                                {t("お申し込み・お問い合わせ")}
                                            </Link>
                                        </Box>
                                    </FadeAndSlideAnimation>
                                </Grid>
                            </Grid>
                    }
                </ScrollTrigger>
            </Box>

            <Box py={4} />
        </main>
    </>;
};

const blue = css({
    color: "white",
    background: "#14188d",
    borderRadius: "8px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "120px",
});

const a = css`
    width: 100%;
    height: 4rem;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    opacity: 1;
    transition: all .3s;
    font-size: 1rem;
    border-radius: 8px;

    &:hover {
        opacity:.8;
        transition:all .3s;
    }
`;
